import { Grid } from "@mui/material";
import useStyles from "routes/style-jss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import React from "react";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import apiServices from "services/RequestHandler";

const Breadcrumbs = () => {
  const classes = useStyles() as any;
  let BreadcrumbTitle = useSelector((state: RootState) => state.breadcrumb);
  const [time, setTime] = React.useState(new Date());
  const user = getDataFromLocalStorage("user");
  const [ip, setIP] = React.useState("");

  const get_user_ip = async () => {
    try {
      let response = await apiServices.getFromApi("/orders/get-user-ip", "");
      setIP(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    let timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    get_user_ip();
  }, []);

  return (
    <Grid container>
      <Grid
        xs={12}
        sm={12}
        md={1.5}
        lg={1.5}
        style={{
          background:
            ip === "185.52.131.51"
              ? "#005177"
              : ip === "185.52.129.152"
              ? "#005177"
              : ip === "185.52.131.5"
              ? "green"
              : (user?.[0] || {}).stores?.length > 1
              ? "#000000"
              : ((user?.[0] || {}).stores?.[0] || {})?.store_code === "_blue"
              ? "#005177"
              : ((user?.[0] || {}).stores?.[0] || {})?.store_code === "_green"
              ? "green"
              : "black",
          padding: "10px 40px",
          boxShadow:
            "0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f",
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: 55,
        }}
      >
        <div
          className={classNames(classes.breadcrumb_time)}
          style={{
            color: "white",
          }}
        >
          {ip === "185.52.131.51"
            ? "Blue Zone"
            : ip === "185.52.129.152"
            ? "Blue Zone"
            : ip === "185.52.131.5"
            ? "Green Zone"
            : (user?.[0] || {}).stores?.length > 1
            ? "All Zones"
            : ((user?.[0] || {}).stores?.[0] || {})?.store_code === "_blue"
            ? "Blue Zone"
            : ((user?.[0] || {}).stores?.[0] || {})?.store_code === "_green"
            ? "Green Zone"
            : (((user?.[0] || {}).stores?.[0] || {})?.store_name || {}).en}
        </div>
      </Grid>

      <Grid xs={12} sm={12} md={10.5} lg={10.5}>
        <div className={classes.breadcrumb_container}>
          <Grid container spacing={2}>
            <Grid
              xs={12}
              md={7}
              item
              className={classNames(
                classes.align_center,
                classes.breadcrumb_head
              )}
            >
              {BreadcrumbTitle?.title || ""}
            </Grid>

            <Grid
              xs={12}
              md={5}
              item
              className={classNames(
                classes.align_end,
                classes.justify_center,
                classes.direction_column
              )}
            >
              <div className={classNames(classes.breadcrumb_time)}>
                {moment().format("dddd[,] DD MMMM YYYY") +
                  " " +
                  moment(time).format("HH:mm")}
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default Breadcrumbs;
