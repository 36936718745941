import { useRoutes } from "react-router-dom";
import { MainAppRoutes } from "./MainAppRoutes";
import useStyles from "./style-jss";
import AuthenticationRoutes from "./AuthenticationRoutes";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import { UserRoutes } from "./UserRoutes";

export function AppRoute() {
  const classes = useStyles();

  const privateRoutes = MainAppRoutes({ classes });
  const authRoutes = AuthenticationRoutes({ classes });
  const userRoutes = UserRoutes({ classes });

  const user = getDataFromLocalStorage("user");

  const getRoutes = () => {
    if (!user) return authRoutes;

    const authorizedIds = ["556649742", "554373767", "504603366", "504603366"];
    if (user?.[0]?.emp_id && authorizedIds.includes(user[0].emp_id)) {
      return userRoutes;
    }

    return privateRoutes;
  };

  const element = useRoutes([getRoutes()]);

  return element;
}

export default AppRoute;
