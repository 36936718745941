export const defaultLabelColors = {
  color_e: "#181818",
  color_f: "#666666",
  color_g: "#eeeeee",
  color_h: "#f8f8f8",

  main_orange: "#ee6931",
  light_orange: "#f9c9b3",
  sand: "#f9d9b6",
  main_blue: "#3026f1",
  dark_blue: "#191847",
  orange_yellow: "#d3be00",
  green: "green",
  red: "#f10000",
};

export const dateRangeFilters = [
  { label: "All Time Records", value: "all_time" },
  { label: "This Year", value: "this_year" },
  { label: "This Quarter", value: "this_quarter" },
  { label: "This Month", value: "this_month" },
  { label: "This Week", value: "this_week" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Previous Week", value: "previous_week" },
  { label: "Previous Month", value: "previous_month" },
];
