import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, ButtonBase, Tooltip } from "@mui/material";
import { LogoutOutlined } from "@mui/icons-material";
import {
  Person2Outlined,
  Fullscreen,
  FullscreenExit,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "ui-components/Toast";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "utils/LocalStore.util";
import logo from "../../../assets/images/logo/dominos-white-logo.png";
import apiServices from "services/RequestHandler";

const elem = document.documentElement;

const Header = ({ drawerOpen }: any) => {
  const theme = useTheme() as any;
  const navigate = useNavigate();
  const [fullScreen, setFullScreen] = useState(false);

  // eslint-disable-next-line
  const [user, setUser] = useState(getDataFromLocalStorage("user"));

  const openFullScreen = () => {
    setFullScreen(true);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  };

  const closeFullScreen = () => {
    setFullScreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleLogout = async () => {
    try {
      await apiServices.postFromApi("/users/admin-logout", {}, "");
      clearAllDataFromLocalStorage();
      Toast.showSuccess("Logged Out Successfully");
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: 210,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
          justifyContent: drawerOpen ? "flex-end" : "flex-start",
        }}
      >
        <ButtonBase sx={{ borderRadius: "12px", marginLeft: 3 }}>
          <img
            src={logo}
            alt={"Domino's Logo"}
            width={200}
            style={{ marginTop: "12px", marginBottom: "12px" }}
          />
        </ButtonBase>
      </Box>

      <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
        {" "}
        {user?.[0]?.emp_id !== "556649742" &&
        user?.[0]?.emp_id !== "554373767" &&
        user?.[0]?.emp_id !== "504603366" &&
        user?.[0]?.emp_id !== "504603366" ? (
          <span
            style={{
              fontSize: 18,
              color: "#fff",
              marginRight: 20,
              cursor: "pointer",
              letterSpacing: 2,
              fontFamily: "BebasNeue-Bold",
              textDecoration:
                window.location.href.indexOf("dashboard") !== -1
                  ? "underline"
                  : "none",
              textDecorationThickness:
                window.location.href.indexOf("dashboard") !== -1
                  ? "3px"
                  : "none",
            }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Dashboard
          </span>
        ) : null}
        <span
          style={{
            fontSize: 18,
            color: "#fff",
            marginRight: 20,
            cursor: "pointer",
            letterSpacing: 2,
            fontFamily: "BebasNeue-Bold",
            textDecoration:
              window.location.href.indexOf("pos") !== -1 ? "underline" : "none",
            textDecorationThickness:
              window.location.href.indexOf("pos") !== -1 ? "3px" : "none",
          }}
          onClick={() => {
            navigate("/pos");
          }}
        >
          POS
        </span>
        <span
          style={{
            fontSize: 18,
            color: "#fff",
            marginRight: 20,
            cursor: "pointer",
            letterSpacing: 2,
            fontFamily: "BebasNeue-Bold",
            textDecoration:
              window.location.href.indexOf("orders") !== -1
                ? "underline"
                : "none",
            textDecorationThickness:
              window.location.href.indexOf("orders") !== -1 ? "3px" : "none",
          }}
          onClick={() => {
            navigate("/orders");
          }}
        >
          Orders
        </span>
        <span
          style={{
            fontSize: 18,
            color: "#fff",
            marginRight: 20,
            cursor: "pointer",
            letterSpacing: 2,
            fontFamily: "BebasNeue-Bold",
            textDecoration:
              window.location.href.indexOf("inventory") !== -1
                ? "underline"
                : "none",
            textDecorationThickness:
              window.location.href.indexOf("inventory") !== -1 ? "3px" : "none",
          }}
          onClick={() => {
            navigate("/inventory");
          }}
        >
          Inventory
        </span>
      </Box>
      <Box sx={{ flexGrow: 1 }} />

      <div
        style={{
          fontSize: 20,
          color: "white",
          marginRight: 20,
          cursor: "pointer",
          letterSpacing: 2,
          fontFamily: "BebasNeue-Bold",
          textTransform: "uppercase",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Person2Outlined
          style={{
            fontSize: 28,
            color: "white",
            marginRight: 5,
          }}
        />
        {user?.[0]?.firstname + " " + user?.[0]?.lastname}
      </div>

      {fullScreen ? (
        <Tooltip title="Exit Full Screen" placement="bottom">
          <FullscreenExit
            style={{
              fontSize: 28,
              color: "white",
              cursor: "pointer",
              marginRight: 20,
            }}
            onClick={closeFullScreen}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Full Screen" placement="bottom">
          <Fullscreen
            style={{
              fontSize: 28,
              color: "white",
              cursor: "pointer",
              marginRight: 20,
            }}
            onClick={openFullScreen}
          />
        </Tooltip>
      )}

      <LogoutOutlined
        style={{
          fontSize: 28,
          color: "white",
          marginRight: 20,
          cursor: "pointer",
        }}
        onClick={handleLogout}
      />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  drawerOpen: PropTypes.bool,
};

export default Header;
