import { configureStore } from "@reduxjs/toolkit";
import BreadcrumbSlice from "./breadcrumbReducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
  reducer: {
    breadcrumb: BreadcrumbSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
