import { lazy } from "react";
import Loadable from "ui-components/Loadable";

export const Login = Loadable(lazy(() => import("views/Auth/Login")));

export const Dashboard = Loadable(lazy(() => import("views/Dashboard")));

export const POS = Loadable(lazy(() => import("views/POS")));

export const Orders = Loadable(lazy(() => import("views/Orders")));

export const Inventory = Loadable(lazy(() => import("views/Inventory")));

export const NotFound = Loadable(lazy(() => import("views/NotFound")));
