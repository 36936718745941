import MainLayout from "layout/MainLayout";
import { Inventory, NotFound, Orders, POS } from "./PageAsync";
import PropTypes from "prop-types";

export function UserRoutes({ classes }: any) {
  const UserRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <POS classes={classes} />,
      },
      {
        path: "/pos",
        element: <POS classes={classes} />,
      },
      {
        path: "/orders",
        element: <Orders classes={classes} />,
      },
      {
        path: "/inventory",
        element: <Inventory classes={classes} />,
      },
      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };

  return UserRoutes;
}

UserRoutes.propTypes = {
  classes: PropTypes.object,
};
