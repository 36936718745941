import { useTheme } from "@emotion/react";
import React from "react";
import styled from "@emotion/styled";
import { AppBar, Box, CssBaseline, Toolbar } from "@mui/material";
import { Outlet } from "react-router";
import Breadcrumbs from "ui-components/extended/Breadcrumbs";
import Header from "./Header";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import { useNavigate, useLocation } from "react-router-dom";

const Main = styled("main", {
  shouldForwardProp: (prop: any) => prop !== "open",
})(({ theme, open }: any) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: "100%",
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "100%",
      padding: "16px",
      marginRight: "10px",
    },
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "100%",
    },
  }),
  marginTop: 68,
}));

export default function MainLayout() {
  const theme = useTheme() as any;
  const navigate = useNavigate();
  const location = useLocation();

  const checkUser = () => {
    try {
      let user = getDataFromLocalStorage("user");

      if (user) {
        navigate(location.pathname);
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    checkUser();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.dominos.dominos_blue,
        }}
      >
        <Toolbar style={{ padding: 0 }}>
          <Header />
        </Toolbar>
      </AppBar>

      <Main theme={theme}>
        <Breadcrumbs />
        <Outlet />
      </Main>
    </Box>
  );
}
