import MainLayout from "layout/MainLayout";
import { Dashboard, Inventory, NotFound, Orders, POS } from "./PageAsync";
import PropTypes from "prop-types";

export function MainAppRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <POS classes={classes} />,
      },
      {
        path: "/dashboard",
        element: <Dashboard classes={classes} />,
      },
      {
        path: "/pos",
        element: <POS classes={classes} />,
      },
      {
        path: "/orders",
        element: <Orders classes={classes} />,
      },
      {
        path: "/inventory",
        element: <Inventory classes={classes} />,
      },
      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };

  return MainRoutes;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
